import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import TalentGrid from '../components/TalentGrid';
import { Box, Container, Flex } from '@chakra-ui/react';
import { spacing, breakpoints, colors } from '../theme/themeAlopex';
import { H1, H2 } from '../theme/Headings';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import SVGHat from '../images/SortingHat.svg';
import SVGMyers from '../images/MyersBriggs.svg';

const TalentPost = ({ data, pageContext }) => {
  const ogImage = !!data.page.seoFields?.ogImage ? `https://www.alopexid.com${data.page.seoFields.ogImage.replace('/static', '')}"` : null

  return (
    <Layout>
      <SEO title={data.page?.seoFields?.seoTitle} description={data.page?.seoFields?.seoDesc} ogImage={ogImage} />
      <Box
        sx={{
          '.talentPhoto': {
            '&::before, &::after': {
              backgroundPosition: ['75% 20%!important', '85% 20%!important', null, '50% 20%!important'],
            },
          },
        }}
      >
        <BackgroundImage
          fluid={data.page.fields.imageRel.childImageSharp.fluid}
          className="talentPhoto"
          style={{
            paddingTop: spacing.header,
            paddingBottom: spacing.groups,
            backgroundColor: colors.brand.slate,
            minHeight: '75vh',
          }}
        >
          <Container maxWidth={breakpoints.lg} color={colors.brand.white} textAlign="left">
            <H1 variant="talentHeader" color={colors.brand.white}>
              {data.page.fName} {data.page.lName}
            </H1>
            <H2 variant="talentHeader" textTransform="uppercase" color={colors.brand.white}>
              {data.page.position}
            </H2>
            <Box maxWidth={['100%', null, null, '50%']}>
              <ReactMarkdown remarkPlugins={[gfm]} children={data.page.bio} />
            </Box>
            <Flex
              flexDirection={['column', null, 'row']}
              alignItems="center"
              sx={{
                '> div': {
                  border: '1px solid white',
                  padding: '14px 16px',
                  display: 'flex',
                  width: ['100%', null, 'auto'],
                  '&:not(:last-of-type)': {
                    marginRight: [0, 0, '20px'],
                    marginBottom: ['10px', null, 0],
                  },
                  img: {
                    marginRight: '14px',
                  },
                },
              }}
            >
              {data.page.house !== 'na' && (
                <Box>
                  <img src={SVGHat} alt="Sorting Hat House" /> {data.page.house}
                </Box>
              )}
              {data.page.myers !== 'na' && (
                <Box>
                  <img src={SVGMyers} alt="Myers-Briggs" />
                  {data.page.myers}
                </Box>
              )}
            </Flex>
          </Container>
        </BackgroundImage>
      </Box>
      <TalentGrid />
    </Layout>
  );
};

export const query = graphql`
  query talentQuery($id: String!) {
    page: talentJson(id: { eq: $id }) {
      fName
      lName
      position
      roles
      photo
      bio
      house
      myers
      fields {
        imageRel {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      seoFields {
        seoTitle
        seoDesc
        ogImage
      }
    }
  }
`;

export default TalentPost;
