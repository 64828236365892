import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router"; //  Import the useLocation hook

const SEO = ({ description, lang, meta, title, schemaMarkUp, ogImage, noIndex }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
          }
        }
      }
    `
  );

  const { pathname } = useLocation(); // Get the current pathname
  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const defaultOGImage = ogImage || "https://www.alopexid.com/images/Alopex-Full-Service-Digital-Agency.png";

  // Dynamically generate the canonical URL
  const canonicalUrl = `${site.siteMetadata.url}${pathname}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || "Alaska Marketing Agency"}
      titleTemplate={(title + ` | Alopex Interaction Design`).length > 70 ? title + ` | AlopexID` : title + ` | Alopex Interaction Design`}
    >
      {noIndex && <meta name="robots" content="noindex" />}
      <meta name="title" content={`${title} | ${defaultTitle}`} />
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={`${title} | Alopex Interaction Design`} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata?.author || ""} />
      <meta name="twitter:title" content={`${title} | Alopex Interaction Design`} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="og:image" content={defaultOGImage} />
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {schemaMarkUp && <script type="application/ld+json">{JSON.stringify(schemaMarkUp)}</script>}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  schemaMarkUp: PropTypes.object,
  ogImage: PropTypes.string,
  noIndex: PropTypes.bool,
};

export default SEO;
