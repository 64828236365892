import React, { useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import {
  Container,
  Box,
} from '@chakra-ui/react'
import { H1, H2 } from '../theme/Headings'
import { spacing, breakpoints } from '../theme/themeAlopex'
import ColorContext from '../context/ColorContext'

const ServicesIndex = ({ data }) => {
  const { color } = useContext(ColorContext)
  const { page } = data

  return (
    <Layout>
      <SEO title="Service Pages Index" description="Our master list of Services." noIndex/>
      {/* SERVICES */}
      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        <H1 variant="pageHeader" color={color.alpha}>Alopex Service Pages</H1>
        <H2 textAlign="center">The Master List</H2>
      </Container>
      <Container maxWidth={breakpoints.lg} pb={[spacing.groups, null, spacing.section]} textAlign="center">
        <Box as="ul">
          {page?.nodes.map((svc, i) => {
            return (
              <li key={`key-svc-link-${i}`}>
                <a href={`/services/${svc.slug}`}>{svc.title}</a>
              </li>
            )
          })}
        </Box>
      </Container>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query allService2024Query {
    page: allService2024Json {
      nodes {
        title
        slug
      }
    }
  }
`

export default ServicesIndex
