import React, { useContext } from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Container, Box } from '@chakra-ui/react'
import { H1 } from '../theme/Headings'
import { spacing, breakpoints } from '../theme/themeAlopex'
import ColorContext from '../context/ColorContext'
import FooterPioneer from "../components/Footer-Pioneer"
import { graphql } from "gatsby"

const NotFoundPage = ({ data }) => {
  const { color } = useContext(ColorContext)

    return (
      <Layout>
        <SEO title="404: Not found" />
        <Container maxWidth={breakpoints.xl} pt={spacing.header} mb={spacing.groups}>
          <H1 variant="pageHeader" color={color.alpha}>404: Not Found</H1>
          <Box as="p" textAlign="center">You just hit a route that doesn&#39;t exist... the sadness.</Box>
        </Container>
          <FooterPioneer fTitle="Let Alopex take you further." weather={data.openWeather} />
      </Layout>
    )
  }
export const query = graphql`
  query alo404 {
    openWeather {
      main {
        temp
      }
      sys {
        sunrise
        sunset
      }
    }
  }
`
export default NotFoundPage