import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import {
  Container,
  Text,
  Box,
  FormControl,
  Input,
  Textarea,
  FormLabel,
} from '@chakra-ui/react'
import { H1 } from '../theme/Headings'
import { spacing, colors, breakpoints } from '../theme/themeAlopex'
// import BackgroundImage from 'gatsby-background-image'
import ColorContext from '../context/ColorContext'
// Newsletter
import Newsletter from '../components/Newsletter'

const SupportTicket = ({ data }) => {
  const { color } = useContext(ColorContext)

  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })

  return (
    <Layout>
      <SEO title='The Alopex Designers & Developers - Alopex' noIndex/>
      <Box backgroundColor={color.alpha}>
        <Container maxWidth={breakpoints.xl} pt={spacing.header} pb={spacing.section} color={colors.brand.white}>
          <Text align="center" color={colors.brand.lightGray}>You can call us directly if you want to discuss any issue. Call Bobby @ <a href="tel:+9073510365">907-351-0365</a> or Kyle @ <a href="tel:+9079525953">907-952-5953</a>.</Text>
          <H1 variant="pageHeader" color={colors.brand.white} mb={spacing.groups}>Alopex Support Ticket Form</H1>
          <form id="supportTicket" name="SupportTicket" method="POST" action="https://getform.io/f/29397a92-6b00-4b59-aede-cecbfc5273bf" enctype="multipart/form-data">
            <FormControl id="email" mb="10px" isRequired>
              <Input name="Email" type="email" placeholder="Email" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <FormControl id="url" mb="10px" isRequired>
              <FormLabel color={colors.brand.lightGray}>Please paste the URL of the page receiving the error.</FormLabel>
              <Input name="Url of Error" type="tel" placeholder="Please paste URL Here" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <FormControl id="browser-type" mb="10px" isRequired>
              <FormLabel color={colors.brand.lightGray}>Go to <a href="https://www.whatsmybrowser.org/" target="_blank" rel="noreferrer">https://www.whatsmybrowser.org/</a> and paste your result below.</FormLabel>
              <Input name="Browser" type="tel" placeholder='Select "copy" under the browser tag and paste here.' borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <FormControl id="issue" mb="10px" isRequired>
              <FormLabel color={colors.brand.lightGray}>Please describe the issue(s).</FormLabel>
              <Textarea name="Issue" type="tel" placeholder="Please list and describe the issue(s) under the indicated url." borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <FormControl id="photos" mb="10px">
              <FormLabel color={colors.brand.lightGray}>Please upload any supporting screenshots for your ticket.</FormLabel>
                <Input name="screenshots[]" className="file-upload" type="file" accept=".jpeg,.jpg,.gif,.png,.pdf" multiple/>
              </FormControl>
            <Input
              type="submit"
              mt="20px"
              value="Send"
              _hover={{
                color: color.alpha,
              }}
            />
          </form>
        </Container>
      </Box>
      <Newsletter title={data.contentJson.title} copy={data.contentJson.newsletterCopy} origin="contact" />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query aloSupport {
    site {
      siteMetadata {
        title
      }
    }
    contentJson(slug: {eq: "contact"}) {
      title
      newsletterCopy
    }
  }
`

export default SupportTicket
