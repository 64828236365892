import React, { useContext, useEffect, useState, useRef, createRef } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import {
  Container,
  Flex,
  Box,
  SimpleGrid,
} from '@chakra-ui/react'
import { H1, H4 } from '../theme/Headings'
import { Button } from '../theme/Buttons'
import { spacing, colors, hexToRGB, breakpoints } from '../theme/themeAlopex'
import ColorContext from '../context/ColorContext'
// Newsletter
import Newsletter from '../components/Newsletter'
import FooterPioneer from "../components/Footer-Pioneer"

const NewsPage = ({ data }) => {
  const [ filter, setFilter ] = useState(['branding', 'design', 'social media', 'web', 'seo', 'sem', 'strategy', 'video'])
  const refNews = useRef([])
  const { color } = useContext(ColorContext)

  refNews.current = data.allPostsJson.edges.map(() => createRef())

  const hasMatch = (arr1, arr2) => {
    let ret = []
    for(let i in arr1) {   
        if(arr2.indexOf(arr1[i]) > -1){
            ret.push(arr1[i])
        }
    }
    return ret
  }

  const handleFilter = (cat) => {
    if (filter[0] === cat && (filter.length <= 1 || cat === 'all')) {
      setFilter(['branding', 'design', 'social media', 'web', 'seo', 'sem', 'strategy', 'video'])
    } else {
      setFilter([cat])
    }
  }

  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })

  const ogImage = !!data.contentJson.seoFields.ogImage ? `https://www.alopexid.com${data.contentJson.seoFields.ogImage.replace('/static', '')}"` : null

  return (
    <Layout>
      <SEO title={data.contentJson?.seoFields?.seoTitle} description={data.contentJson?.seoFields?.seoDesc} ogImage={ogImage} />
      <Container maxWidth={breakpoints.xl} pt={spacing.header} mb={spacing.groups}>
        <H1 variant="pageHeader" color={color.alpha}>Research & News</H1>
        <Flex
          sx={{
            button: {
              '&:not(:last-of-type)': { marginRight: '-1px' },
              '&:hover': { opacity: 0.9 }
            }}}
          flexDirection={['column', null, 'row']}
          justifyContent="center"
        >
          <Button variant="buttonNewsFilter" onClick={() => handleFilter('all')} color={colors.brand.white} style={{backgroundColor: filter.length > 1 ? color.bravo : colors.brand.slate, height: 'auto'}}>All</Button>
          <Button variant="buttonNewsFilter" onClick={() => handleFilter('branding')} color={colors.brand.white} style={{backgroundColor: filter.length < 2 && filter[0] === 'branding' ? color.bravo : colors.brand.slate, height: 'auto'}}>Branding</Button>
          <Button variant="buttonNewsFilter" onClick={() => handleFilter('design')} color={colors.brand.white} style={{backgroundColor: filter.length < 2 && filter[0] === 'design' ? color.bravo : colors.brand.slate, height: 'auto'}}>Design</Button>
          <Button variant="buttonNewsFilter" onClick={() => handleFilter('social media')} color={colors.brand.white} style={{backgroundColor: filter.length < 2 && filter[0] === 'social media' ? color.bravo : colors.brand.slate, height: 'auto'}}>Social Media</Button>
          <Button variant="buttonNewsFilter" onClick={() => handleFilter('web')} color={colors.brand.white} style={{backgroundColor: filter.length < 2 && filter[0] === 'web' ? color.bravo : colors.brand.slate, height: 'auto'}}>Web</Button>
        </Flex>
        {/* {filter.map((f)=> f )} */}
      </Container>
      {/* News Grid */}
      <SimpleGrid columns={[1, 1, 2, null, 3, 4]} gap="1px" background={`linear-gradient(135deg, ${color.alpha}, ${color.bravo})`} backgroundColor={color.bravo}>
        {data.allPostsJson.edges.map((entry, i) => {
          return (
            <>
            {hasMatch(entry.node.category, filter).length > 0 &&
            <Box paddingTop="75%" height="0" position="relative">
              <Box
                color={colors.brand.white}
                borderRight="1px solid white"
                borderBottom="1px solid white"
                position="absolute"
                width="100%"
                height="100%"
                top="0"
                left="0"
                alignItems="flex-end"
                overflow="hidden"
                cursor="pointer"
                _hover={{
                  '.back': {
                    top: 'calc(0% - 1px)',
                  },
                  '.front': {
                    top: '-100%',
                  }
                }}
              >
                <Link to={`/news/${entry.node.slug}/`}>
                <Flex className="back" backgroundColor={hexToRGB(colors.brand.slate, 0.3)} borderTop="1px solid white" position="absolute" width="100%" height="100%" alignItems="flex-end" top="100%" left="0" transition="top 0.3s ease-in-out" padding="1em" overflow="hidden">
                  <Box as="p">{entry.node.excerpt}</Box>
                    <Box position="absolute" backgroundColor={colors.brand.white} top="0" left="1em" color={color.alpha} padding="1em 0.5em" textTransform="capitalize" lineHeight="1.2">
                      {entry.node.category.map((c, i) => { return (<><span>{c}</span><br /></>) })}
                    </Box>
                </Flex>
                </Link>
                <Flex className="front" position="absolute" width="100%" height="100%" alignItems="flex-end" top="0" left="0" transition="top 0.3s ease-in-out" padding="1em">
                  <H4 mb="0" lineHeight="1" maxWidth="250px">{entry.node.title}</H4>
                </Flex>
              </Box>
            </Box>
            }
            </>
          )
        })}
      </SimpleGrid>
      {/* <Newsletter title={data.contentJson.title} copy={data.contentJson.newsletterCopy} origin="news" /> */}
      <FooterPioneer fTitle="Let Alopex take you further." weather={data.openWeather} />
    </Layout>
  )
}

export const query = graphql`
  query aloNews {
    site {
      siteMetadata {
        title
      }
    }
    contentJson(slug: {eq: "news"}) {
      title
      newsletterCopy
      seoFields{
        seoTitle
        seoDesc
        ogImage
      }
    }
    allPostsJson(sort: {fields: date, order: DESC}, limit: 100) {
      edges {
        node {
          title
          excerpt
          slug
          body
          date
          category
          id
        }
      }
    }
    openWeather {
      main {
        temp
      }
      sys {
        sunrise
        sunset
      }
    }
  }
`

export default NewsPage
