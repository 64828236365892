import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Box, Flex, Button } from '@chakra-ui/react'
import { H2 } from '../theme/Headings'
import { spacing, breakpoints, colors, hexToRGB } from '../theme/themeAlopex'
import ColorContext from '../context/ColorContext'
import { Link } from 'gatsby'

const FooterPioneer = ({ fTitle, weather }) => {
  const { color } = useContext(ColorContext)
    // Open Weather Map Daylight Hours
    const daylight = (((weather.sys.sunset - weather.sys.sunrise) / 60) / 60)
    const hrsOfDaylight = Math.floor(daylight)
    const fraction = daylight - hrsOfDaylight
    const minOfDaylight = Math.floor(fraction * 60)

  // Global Settings
  const { settings } = useStaticQuery(
    graphql`
      query {
        settings: contentJson(slug: {eq: "globalSettings"}) {
          phone
        }
      }
    `
  )

  return (
    <Box
      as="footer"
      color={colors.brand.white}
      position="relative"
      background={`linear-gradient(180deg, #f1f1f1, rgba(255, 255, 255, 0)), linear-gradient(135deg, #f1f1f1, ${color.alpha})` }
      sx={{
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '0',
          width: '100%',
          height: '50%',
          backgroundImage: 'url(/images/pioneer-peak-full.webp)',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          mixBlendMode: 'overlay',
          pointerEvents: 'none',
          zIndex: '10',
          opacity: 0.7
        }
      }}
    >
      <Container maxWidth={breakpoints.xl} p={`${spacing.groups} 1rem`} position="relative" zIndex="1">
        <Box
          backgroundColor={color.alpha}
          borderRadius="10px"
          padding={['16px 18px', '28px 32px']}
        >
          <Flex justifyContent="space-between" mb={spacing.icon}>
            <Box>
              <H2 variant="figtree">{fTitle ? fTitle : "Let Alopex take you further."}</H2>
              <Flex gridGap="10px" sx={{
                a: {
                  color: color.alpha,
                  textTransform: 'uppercase',
                  padding: '0 24px',
                  '@media (min-width: 640px)': {
                    padding: '0 34px',
                  },
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: `0 4px 8px rgba(0, 0, 0, 0.4)`,
                    color: color.bravo
                  }
                }
              }}
              >
                <Button as="a" href={`sms:${settings?.phone}`} variant="buttonMorphicSm">Text</Button>
                <Button as="a" href={`tel:${settings?.phone}`} variant="buttonMorphicSm">Call</Button>
              </Flex>
            </Box>
            <Box
              as="nav"
              display={['none', null, 'block']}
              sx={{
                ul: {
                  margin: 0,
                  listStyleType: 'none',
                  li: {
                    marginBottom: '8px',
                    textAlign: 'right',
                    a: {
                      fontSize: '2rem',
                      textDecoration: 'none',
                      fontWeight: 'light',
                      transition: 'color 0.3s ease-in-out',
                      lineHeight: 1,
                      '&:hover': {
                        color: color.bravo
                      }
                    },
                  }
                }
              }}
            >
              <ul>
                <li><a href="/#our-services">Services</a></li>
                <li><Link to="/our-work/">Our Work</Link></li>
                <li><Link to="/news/">News</Link></li>
                <li><Link to="/contact/">Contact</Link></li>
              </ul>
            </Box>
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <Box display={['none', null, 'block']}>
              <svg viewBox="0 0 60 80" style={{maxWidth: '40px', width: '100%', marginBottom: '10px'}}>
                <path fill="#FFFFFF" d="M7.67,38.4,0,.5,29.91,29.3,60,0,52.52,38.4,29.91,80Zm23,37.2L50.73,38.5,30.66,31.4Zm-1.42,0V31.4L9.37,38.5ZM51.29,37.1,57.73,4.3,31.32,30ZM8.9,37.1,28.58,30,2.37,4.8Z" />
              </svg>
              <Box as="p" mb="0" fontWeight="100" lineHeight="1.15">
                <strong style={{fontWeight: '500'}}>Alopex</strong> noun<br/>
                Alo•pex  <small>əˈlōˌpeks,  ˈaləˌp-</small><br/>
                <small>: a genus (family Canidae) comprising the arctic foxes</small><br/>
                <small>New Latin, from Greek alōpēx fox</small>
              </Box>
            </Box>
            <Box
              textAlign={['left', null, 'right']}
              sx={{
                fontSize: '21px',
                fontWeight: 100,
                small: {
                  fontSize: '12px',
                  span: {
                    backgroundColor: hexToRGB(colors.brand.slate, 0.45 ),
                    borderRadius: '4px',
                    padding: '2px 4px',
                  }
                }
              }}
            >
              Established 2013.<br/>
              <Box lineHeight="1">Our <Link to="/talent/">team</Link> is located in <Link to='https://g.page/r/CZfgTZQmXobfEBM/'>Alaska</Link></Box>
              <small>Palmer, Alaska <span>{`${Math.round(weather.main.temp)}°F`}</span> <span>{hrsOfDaylight}:{minOfDaylight < 10 ? `0${minOfDaylight}` : minOfDaylight} hours of daylight today</span></small>
            </Box>
          </Flex>
        </Box>
        <Box
          display={[null, null, 'none']}
          marginTop={spacing.icon}
          sx={{
            span: {
              backgroundColor: hexToRGB(colors.brand.slate, 0.45 ),
              borderRadius: '4px',
              padding: '2px 4px',
              fontSize: '12px'
            }
          }}
        >
          <Box as="p" mb="0" fontWeight="100" lineHeight="1.15">
            <strong style={{fontWeight: '500'}}>Alopex</strong> noun<br/>
            Alo•pex  <small>əˈlōˌpeks,  ˈaləˌp-</small><br/>
            <small>: a genus (family Canidae) comprising the arctic foxes</small><br/>
            <small>New Latin, from Greek alōpēx fox</small>
          </Box>
        </Box>
        <Box textAlign={['left', null, 'center']} marginTop={spacing.icon}>
          <Box as="a" fontSize="smaller" textDecoration="none" href="https://www.iubenda.com/privacy-policy/69654251" target="_blank" rel="noreferrer">Privacy Policy</Box>
        </Box>
      </Container>
    </Box>
  )
}

export default FooterPioneer