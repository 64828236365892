import React, { useContext } from 'react'
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Container, Box } from '@chakra-ui/react'
import { spacing, breakpoints } from "../theme/themeAlopex"
import { H1 } from '../theme/Headings'
import ColorContext from '../context/ColorContext'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import FooterPioneer from '../components/Footer-Pioneer'

const NewsPost = ({ data, context }) => {
  const { color } = useContext(ColorContext)

  const schema = {
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": data.page.title,
  "description": !!data.page.seoSchema[0] ? data.page.seoSchema[0].blurb : "",
  "image": !!data.page.seoSchema[0] ? data.page.seoSchema[0].artImage : '',  
  "author": {
    "@type": "Person",
    "name": !!data.page?.author ? data.page.author : 'by Kyle Fox'
  },  
  "publisher": {
    "@type": "Organization",
    "name": "AlopexID",
    "logo": {
      "@type": "ImageObject",
      "url": "https://alopexid.com/images/alopex-logo.png"
    }
  },
  "datePublished": !!data.page?.seoSchema ? data.page.seoSchema.datePub : '',
  "dateModified": !!data.page?.seoSchema ? data.page.seoSchema.dateMod : ''
  };
  const seoTitle = !!data.page.seoFields ? data.page.seoFields.seoTitle : data.page.title;
  const seoDesc = !!data.page.seoFields ? data.page.seoFields.seoDesc : '';
  const ogImage = !!data.page.seoFields.ogImage ? `https://www.alopexid.com${data.page.seoFields.ogImage.replace('/static', '')}"` : null
  return (
    <Layout>
      <SEO title={seoTitle} description={seoDesc} schemaMarkUp={schema} ogImage={ogImage}/>
      <Container pt={spacing.header} mb={spacing.groups} maxWidth="72ch"
        sx={{
          h1: {
            color: 'rgba(0, 0, 0, 0.1)',
            background: `linear-gradient(to right, ${color.alpha}, ${color.bravo})`,
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            display: 'inline-block',
          }
        }}
      >
        <H1
          variant="figtree"
          textAlign="left"
          mb={spacing.icon}
          textTransform="none"
          fontWeight="300"
          lineHeight="1"
          pb="5px"
        >
          {data.page.title}
        </H1>
        <Box
          as="byline"
          textAlign="left"
          w="100%"
          display="inline-block"
          textTransform="uppercase"
          fontWeight="bold"
          opacity="0.25"
          pl="5px"
        >
          By {!!data.page?.author ? data.page.author : 'Kyle Fox'} {!!data.page?.seoSchema?.dateMod ? ` - Last Updated: ${new Date (data.page.seoSchema.dateMod).toISOString().substring(0,10)}` : console.log(data.page)}
        </Box>
      </Container>
      <Container
        pb={spacing.section}
        maxWidth="72ch"
        sx={{
          'h2, h3': {
            marginTop: spacing.elements,
            paddingBottom: spacing.icon
          },
          'h2': {
            color: 'rgba(0, 0, 0, 0.1)',
            background: `-webkit-linear-gradient(right, ${color.alpha}, ${color.bravo})`,
            WebkitBackgroundClip: 'text',
            display: 'inline-block',
            textTransform: 'uppercase',
            fontWeight: 'bold'
          }
        }}
      >
        <ReactMarkdown remarkPlugins={[gfm]} children={data.page.body.replace('/static', '')} />
      </Container>
      <FooterPioneer fTitle="Let Alopex take you further." weather={data.openWeather} />
    </Layout>
  ) 
}

export const query = graphql`
  query newsQuery($id: String!) {
    page: postsJson(id: { eq: $id }) {
      title
      author
      excerpt
      slug
      body
      date
      seoFields{
        seoTitle
        seoDesc
        ogImage
      }
      seoSchema{
        blurb
        datePub
        dateMod
        artImage
      }
    }
    openWeather {
      main {
        temp
      }
      sys {
        sunrise
        sunset
      }
    }
  }
`

export default NewsPost