import React, { useContext, useEffect, useState, useRef, createRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import TalentGrid from "../components/TalentGrid"
import {
  Container,
  Flex,
  Box,
} from '@chakra-ui/react'
import { H1, H3 } from '../theme/Headings'
import { Button } from '../theme/Buttons'
import { spacing, colors } from '../theme/themeAlopex'
// import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image/withIEPolyfill"
import ColorContext from '../context/ColorContext'
// Newsletter
import Newsletter from '../components/Newsletter'
// gsap
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import FooterPioneer from './../components/Footer-Pioneer';
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const TalentPage = ({ data }) => {
  const [ filter, setFilter ] = useState(['accounts', 'creative', 'development'])
  const refTalent = useRef([])
  const { color } = useContext(ColorContext)


  // console.log('talent data:', data)

  // refTalent.current = talent.map((t) => console.log(genBG(t.roles), t.roles.length) )

  refTalent.current = data.allTalentJson.nodes.map(() => createRef())

  useEffect(() => {
    // Photo fades
    for (let i = 0; i < data.allTalentJson.nodes.length; i++) {
      if (i > (data.allTalentJson.nodes.length / 2)) { // Right photo fades
        const tlRight = gsap.timeline({ repeat: -1 })
        // tlRight.to(refTalent.current[i].current, {
        tlRight.to('.tpLeft', {
          opacity: 1,
          duration: 3,
          stagger: {
            each: 2.8,
            repeat: 1,
            yoyo: true,
          },
        })        
      } else { // Left photo fades
        const tlLeft = gsap.timeline({ repeat: -1, delay: 1 })
        // tlLeft.to(refTalent.current[i].current, {
        tlLeft.to('.tpRight', {
          opacity: 1,
          duration: 3,
          stagger: {
            each: 2.8,
            repeat: 1,
            yoyo: true,
          },
        })
      }
    }
  }, [data.allTalentJson.nodes.length])

  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })

  const ogImage = !!data.contentJson.seoFields.ogImage ? `https://www.alopexid.com${data.contentJson.seoFields.ogImage.replace('/static', '')}"` : null

  return (
    <Layout>
      <SEO title={data.contentJson?.seoFields?.seoTitle} description={data.contentJson?.seoFields?.seoDesc} ogImage={ogImage} />
        {/* Fading photos / Title / Filters */}
        <Flex minHeight="75vh" padding="50px 0" alignItems="center" justifyContent="center" backgroundColor={colors.brand.slate} position="relative">
          {data.allTalentJson.nodes.map((tal, i) => {
            return (
              <Img
                key={`photo-fade-${i}`}
                fluid={tal.fields.imageRel.childImageSharp.fluid}
                style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, transform: (i > (data.allTalentJson.nodes.length / 2)) ? 'scaleX(-1)' : '', opacity: 0, zIndex: 0 }}
                objectPosition="85% 20%"
                className={(i > (data.allTalentJson.nodes.length / 2)) ? 'tpLeft' : 'tpRight'}
                ref={refTalent.current[i]}
              />
            )
          })}
          <Box m={`${spacing.section} 0`} color={colors.brand.white} textAlign="center" position="relative" zIndex="1">
            <H1 variant="pageHeader" color={colors.brand.white}>We are Alopex</H1>
            <H3>Experienced, talented, fresh.</H3>
            <Container>
              <Box as="p">
                Alopex is a team of Alaskan designers and developers. We believe in specializing, allowing each of us to do what we're best at. Click anyone below to learn more about them.
              </Box>
              <Box>
                <Button onClick={() => { setFilter( (filter[0] === 'accounts' && filter.length <= 1 ? ['accounts', 'creative', 'development'] : ['accounts']) ) }} variant="buttonTalentFilter" color={colors.brand.slate} style={{backgroundColor: colors.brand.slate, height: 'auto'}}>Account</Button>
                <Button onClick={() => { setFilter( (filter[0] === 'creative' ? ['accounts', 'creative', 'development'] : ['creative']) ) }} variant="buttonTalentFilter" m="0 -1px" style={{backgroundColor: color.alpha, height: 'auto'}}>Creative</Button>
                <Button onClick={() => { setFilter( (filter[0] === 'development' ? ['accounts', 'creative', 'development'] : ['development']) ) }} variant="buttonTalentFilter" style={{backgroundColor: color.bravo, height: 'auto'}}>Development</Button>
              </Box>
            </Container>
          </Box>
        </Flex>
        {/* Photo Grid */}
        <TalentGrid filter={filter} />
        {/* <Newsletter title={data.contentJson.title} copy={data.contentJson.newsletterCopy} origin="talent" /> */}
        <FooterPioneer fTitle="Let Alopex take you further." weather={data.openWeather} />
      </Layout>
  )
}

export const query = graphql`
  query aloTalents {
    site {
      siteMetadata {
        title
      }
    }
    contentJson(slug: {eq: "talent"}) {
      title
      newsletterCopy
      seoFields {
        seoTitle
        seoDesc
        ogImage
      }
    }
    allTalentJson(sort: {fields: order, order: ASC}) {
      nodes {
        fName
        lName
        position
        roles
        photo
        order
        fields {
          imageRel {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
      openWeather {
      main {
        temp
      }
      sys {
        sunrise
        sunset
      }
    }
  }
  `
  
  export default TalentPage
  