import React, { useContext, useEffect, useState, useRef, createRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from "prop-types"
import { Link } from 'gatsby'
import { Box, Flex, SimpleGrid } from "@chakra-ui/react"
import { colors, typography, hexToRGB } from '../theme/themeAlopex'
import SVGTriangle from "../images/alopex_triangle.svg"
import ColorContext from '../context/ColorContext'
import { Spin as Hamburger } from 'hamburger-react'
import {gsap} from 'gsap'
import './header.scss'
import Logo from './Logo'
import { useLocation } from '@reach/router';

const Header = ({ siteTitle }) => {
  const { partnerNodes } = useStaticQuery(
    graphql`
      query {
        partnerNodes: allContentJson(filter: {slug: {eq: "partners"}}) {
          nodes {
            slug
            partners {
              title
              svg
            }
          }
        }
      }
    `
  )
  const { partners } = partnerNodes.nodes[0]

  const location = useLocation();
  const { color, setColor } = useContext(ColorContext)
  const [isLanding, setIsLanding] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [showAlpha, setShowAlpha] = useState(false)
  const [showBravo, setShowBravo] = useState(false)


  const swatches = [
    // row 1
    '#9c27b0',
    '#673ab7',
    '#3f5185',
    '#2196f3',
    '#03a9f4',
    // row 2
    '#65c9de',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cbd45a',
    // row 3
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#f44336',
    '#e91e63',
  ]

  const staggerSwatches = (panel) => {
    if (panel === 'bravo' && !showBravo) {
      gsap.set('.bravoSwatch', {
        y: 0,
        opacity: 1,
      })
      gsap.from('.bravoSwatch', {
        y: '-30px',
        opacity: 0,
        stagger: 0.02
      })
    } else if (panel === 'alpha' && !showAlpha) {
      gsap.set('.alphaSwatch', {
        y: 0,
        opacity: 1,
      })
      gsap.from('.alphaSwatch', {
        y: '-30px',
        opacity: 0,
        stagger: 0.02
      })
    }
  }

  // Partners
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const parts = document.querySelectorAll('.partnerLogos');
      const pTL = gsap.timeline({repeat: -1})
      for(let i = 0; i < parts.length; i++) {
        pTL.to(parts[i], {opacity: 1})
        pTL.to(parts[i], {opacity: 0, delay: 2})
      }
    }
  }, [])

  return (
    <Box
      as="header"
      background={colors.brand.white}
      color={colors.brand.slate}
      position="fixed"
      width="100%"
      top="0"
      left="0"
      zIndex="1000"
      boxShadow={`0 1px 10px ${hexToRGB(colors.brand.slate, 0.1)}`}
    >

      {/* OFFCANVAS */}
      <Box
        position="fixed"
        width="100vw"
        height="100vh"
        left="0"
        top={() => {return isOpen ? '0' : '-150%'}}
        paddingTop="135px"
        backgroundImage={`linear-gradient(to bottom, ${color.alpha}, ${hexToRGB(color.bravo, 0.8)})`}
        zIndex="999"
        transition="top 0.3s ease-in-out"
        textAlign="center"
      >
        <Flex className="mobileNav" flexDirection="column" alignItems="center">
          <Link to="/">Home</Link>
          <Link to="/#our-services">Services</Link>
          <Link to="/our-work/">Our Work</Link>
          <Link to="/talent/">Talent</Link>
          <Link to="/news/">News</Link>
          <Link to="/contact/">Contact</Link>
        </Flex>
      </Box>

      {/* TOPBAR */}
      <Box
        className="topBar"
        background={colors.brand.white}
        zIndex="1000"
        sx={{
          'flexDirection': 'row-reverse',
          '.logoTriangle': {
            width: '22px',
            height: '30px',
            display: 'block',
            position: 'absolute',
            left: '15px',
            top: '50%',
            transform: 'translateY(-50%)',
            outline: 'none',
            '& svg#AlopexLogoMain': {
              maxWidth: '40px',
              margin: '0',
            },
            '.heart, .partner': {
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)'
            },
            '.heart': {
              left: 'calc(100% + 10px)',
              transformOrigin: '50% 50%',
              '& svg': {
                width: '15px',
                height: '15px'
              }
            },
            '.partner': {
              left: 'calc(100% + 35px)',
              height: '27px',
              width: '135px',
              img: {
                position: 'absolute',
                maxHeight: '100%',
                left: '0',
                top: '50%',
                transform: 'translateY(-50%)',
                height: '100%'
              }
            }
          },
          '@media only screen and (min-width: 1200px)': {
            flexDirection: 'row',
            '.logoTriangle': {
              width: '60px',
              height: '95px',
              left: '50%',
              top: 0,
              transform: 'translateY(0) translateX(-50%)',
              backgroundImage: `url(${SVGTriangle})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom center',
              outline: 'none',
              '& svg#AlopexLogoMain': {
                maxWidth: '40px',
                margin: '15px auto 0 auto',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-2px)'
                }
              },
              '.heart, .partner': {
                display: 'none'
              },
            },
          }
        }}
      >
        
        {/* NAV LEFT */}
        <Box className="navLeft" display={['flex', null, null, null, 'none']}>
          <Hamburger size="25" distance="sm" toggled={isOpen} toggle={setOpen} label="Show menu" />
          <Box onClick={() => {setOpen(!isOpen)}} lineHeight="45px" fontFamily={typography.fonts.heading} fontSize="19px" paddingTop="1px"/>
        </Box>
        <Box as="ul" className="navLeft" display={['none', null, null, null, 'flex']}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <Box
            as="li"
            position="relative"
            _hover={{
              '.menu-drop': {
                display: 'block',
                li: {
                  a: {
                    borderBottom: 'none!important',
                    py: '10px',
                    height: 'auto',
                    '&:hover': {
                      backgroundColor: colors.brand.slate,
                      color: 'white'
                    }
                  }
                }
              }
            }}
          >
            <a>Services</a>
            <Box
              className="menu-drop"
              as="ul"
              display="none"
              position="absolute"
              left="0"
              top="100%"
              backgroundColor="white"
              whiteSpace="nowrap"
            >
              <li><Link to="/services/seo-services/">SEO (Search Engine Optimization)</Link></li>
              <li><Link to="/services/sem-services/">SEM (Search Engine Marketing)</Link></li>
              <li><Link to="/services/custom-website-developers/">Custom Websites</Link></li>
              <li><Link to="/services/squarespace-website-designer/">Squarespace Websites</Link></li>
              <li><Link to="/services/website-support-management/">Website Support & Management</Link></li>
              <li><Link to="/services/branding/">Brand Design</Link></li>
              <li><Link to="/services/print/">Print Design</Link></li>
              <li><Link to="/services/marketing-strategy/">Marketing Strategy</Link></li>
              <li><Link to="/services/api-integration-services/">API Integration</Link></li>
              <li><Link to="/services/ui-ux-designers/">UI & UX Designers</Link></li>
            </Box>
          </Box>
          <li>
            <Link to="/our-work/" activeClassName="active">Our Work</Link>
          </li>
        </Box>

        {/* LOGO TRIANGLE (absolute) */}
        <Box className="logoTriangle">
          <Link to="/">
            <Logo />
          </Link>
          <Box className="heart">
            <svg id="alopexHeart" viewBox="0 0 9.601 8">
              <path id="Path" fill="#37474F" d="M0.695,0.682c-0.927,0.909-0.926,2.373,0,3.283L4.798,8c1.369-1.343,2.738-2.687,4.107-4.03
              c0.927-0.909,0.927-2.374,0-3.283c-0.927-0.909-2.42-0.909-3.347,0L4.802,1.428L4.042,0.682C3.115-0.227,1.622-0.227,0.695,0.682
              L0.695,0.682z"/>
            </svg>
          </Box>
          <Box className="partner">
            {partners.map((p, i) => {
              return <img key={`p-key-${i}`} className="partnerLogos" src={p.svg.replace('/static', '')} style={{opacity: 0}} />
            })}
          </Box>
        </Box>

        {/* NAV RIGHT */}
        <Box as="ul" className="navRight" display={['none', null, null, null, 'flex']}>
          <li>
            <Link to="/talent/" activeClassName="active">Talent</Link>
          </li>
          <li>
            <Link to="/news/" activeClassName="active">News</Link>
          </li>
          <li>
            <Link to="/contact/" activeClassName="active">Contact</Link>
          </li>
        </Box>


      </Box>

      {/* COLOR PICKER */}
      <Flex
        className="colorPicker"
        position="absolute"
        top="50%"
        right={['50px', null, null, null, '10px']}
        transform="translateY(-50%)"
        zIndex="1001"
      >
        <Box
          className="alphaButton"
          position="relative"
          onClick={() => { setShowAlpha(!showAlpha);setShowBravo(false);staggerSwatches('alpha')}}
          cursor="pointer"
          p="12px 5px 12px 8px"
        >
          <Box
            background={color.alpha}
            borderRadius="50%"
            width="20px"
            height="20px"
          />
          <SimpleGrid
            className="alphaSwatches"
            display={() => {return showAlpha ? 'grid' : 'none'}}
            position="absolute"
            top="100%"
            right="0"
            gap="5px"
            columns={5}
            backgroundColor={colors.brand.white}
            padding="12px"
            >
            {swatches.map((swat, i) => {
              return (
                <Box
                  key={`swatch-alpha-${i}`}
                  className="alphaSwatch"
                  backgroundColor={swat}
                  borderRadius="50%"
                  width="20px"
                  height="20px"
                  onClick={() => { setColor({alpha: swat, bravo: color.bravo}) }}
                />
              )
            })}
          </SimpleGrid>
        </Box>
        <Box
          className="bravoButton"
          position="relative"
          onClick={() => { setShowBravo(!showBravo);setShowAlpha(false);staggerSwatches('bravo')}}
          cursor="pointer"
          p="12px 8px 12px 5px"
        >
          <Box
            background={color.bravo}
            borderRadius="50%"
            width="20px"
            height="20px"
          />
          <SimpleGrid
            className="bravoSwatches"
            display={() => {return showBravo ? 'grid' : 'none'}}
            position="absolute"
            top="100%"
            right="0"
            gap="5px"
            columns={5}
            backgroundColor={colors.brand.white}
            padding="12px"
            >
            {swatches.map((swat, i) => {
              return (
                <Box
                  key={`swatch-bravo-${i}`}
                  className="bravoSwatch"
                  backgroundColor={swat}
                  borderRadius="50%"
                  width="20px"
                  height="20px"
                  onClick={() => { setColor({alpha: color.alpha, bravo: swat}) }}
                />
              )
            })}
          </SimpleGrid>
        </Box>
      </Flex>

    </Box>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
